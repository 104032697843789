import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-3">
                <p>
                    <strong>
                        Torna el millor curs online de psicos en directe, ara els divendres!
                    </strong>
                </p>

                <p>Un curs completament renovat amb nous exercicis adaptats als oficials.</p>

                <p>
                    <strong>12 hores de formació en directe</strong> junt amb exercicis a través del
                    campus per poder practicar.
                </p>

                <p>
                    <strong>Dates del curs:</strong>
                </p>
                <ul>
                    <li>Divendres, 11 d'octubre, de 18.00 a 22.00 h</li>
                    <li>Divendres, 18 d'octubre, de 18.00 a 22.00 h</li>
                    <li>Divendres, 25 d'octubre, de 18.00 a 22.00 h</li>
                </ul>

                <p>
                    Les sessions quedaran gravades i disponibles fins al{" "}
                    <strong>30 de novembre</strong>.
                </p>

                <p>
                    <strong>Al campus trobaràs:</strong>
                </p>
                <ul>
                    <li>Vídeos explicatius</li>
                    <li>Exercicis interactius</li>
                    <li>Simulacres de prova</li>
                </ul>

                <p>
                    L'objectiu d'aquest curs intensiu és oferir-te les eines necessàries per
                    afrontar la prova amb les màximes garanties. Sabem que cada any molts aspirants
                    es queden a les portes, i no volem que això et passi a tu.
                </p>

                <p>
                    <strong>Cada raonament compta</strong>, i tots tenen el mateix valor a la prova
                    aptitudinal: raonament abstracte, espacial, verbal, numèric i perceptiu.
                </p>

                <p>
                    Cada lògica es tractarà de forma individual i amb exercicis on detallarem tot el
                    que necessites per poder tenir èxit en aquesta prova.
                </p>

                <p>
                    Explicació i anàlisi de tots els raonaments que surten a la prova (raonament
                    abstracte, espacial, verbal, numèric i perceptiu).
                </p>

                <p>T'esperem!</p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Transforma el teu futur amb el nostre curs intensiu de psicos: 12 hores de formació en directe, exercicis nous i tot el suport que necessites per dominar la prova i garantir el teu èxit. Inscriu-te ara i obtingues l'avantatge que et farà destacar!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
